// Add anything custom here -- mixins, variable overrides.

// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier
//nettz tweaks - easy autocomplete
@import "_easy-autocomplete";
/*** Page Header ***/

.page-header {
  .nav-function-top & {
      box-shadow: none !important;
      background-color: $white !important;
    }
  
    .search {
      display: flex;
      align-items: center;
  
      &:before {
        content: '\f002';
        font-family: 'Font Awesome 5 Pro';
      }
    }
  
    .page-logo-text {
      display: none;
    }
  }

  /*** Sidebar Menu Color Theme***/
.page-sidebar {
    .nav-function-top & {
      background-color: lighten($primary-100, 42%) !important;
      box-shadow: none !important;
    }
    .nav-function-top & .primary-nav .nav-menu{
     > li  a > i{
        color:saturate($color-primary, 50%) !important;
     }
     > li.active a:before{content: '' !important;}
    //  > li:hover > a + ul{
    //     width:0; 
    //     height:0; 
    //     padding:0;
  
    //     &:before,
    //     > li {display: none;}
    //   }
    }
  }
