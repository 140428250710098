// need to override just one or two small stylistic things and don't want to edit the main .scss files?  Add those overrides here
@mixin showUL($d) {
    display: $d !important;
    position: absolute;
    left: 0;
    right: 0;
}

@include media-breakpoint-up($mobile-breakpoint) {
    .nav-function-top .page-sidebar .primary-nav .has-mega-menu.nav-menu {
        // reset old menu
        >li {
            position: static;
        }
        >li.show>a:after {
            content: ' ';
            width: 0;
            height: 0;
            border-width: .5rem;
            border-color: transparent transparent darken($mega-menu-bg, 5%) transparent;
            border-style: solid;
            position: absolute;
            left: 50%;
            bottom: 12px;
            opacity: 0;
        }
        >li.show:hover>a:after {
            opacity: 1;
            transition: opacity 500ms ease-in;
        }
        >li:hover>a+ul {
            @include showUL(flex);
            margin: 1rem 2rem 0 2rem;
            width: calc(100% - 4rem);
            background-color: darken($mega-menu-bg, 5%);
            border-radius: 10px;
            top: 1.75rem;
            &:before {
                display: none;
            }
        }
        .mega-menu-cnt {
            flex-wrap: wrap;
            li:hover>a,
            li>a:hover {
                background-color: transparent !important;
                color: $color-primary;
            }
            a>span {
                font-size: .875rem;
            }
            >li {
                flex: 1 0 250px;
                max-width: 250px;
                margin: .5rem .5rem 0 .5rem;
                >a {
                    font-weight: 500;
                }
                [class*="fa"] {
                    margin-left: 0;
                    margin-right: .75rem;
                }
                >ul {
                    display: block !important;
                    background-color: transparent;
                    padding-top: .25rem;
                    border-top: 1px solid currentColor;
                    >li>a {
                        padding: .25rem 1.2rem;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

// Register Buttons
.page-header a[data-i18n*="allg"] {
    >i[class*="fa-"] {
        margin-right: .25rem;
    }
}